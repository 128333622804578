import styled from '@emotion/styled'
import { FlexBox } from 'app/components/FlexBox'
import React, { memo } from 'react'

import { Props as ZigZagItemProps, ZigZagItem } from './Item'

export interface Props {
  paragraphs: ZigZagItemProps[]
}

export const ZigZag = memo(function ZigZag({ paragraphs }: Props) {
  if (!paragraphs) {
    return null
  }

  return (
    <Container>
      {paragraphs
        ? paragraphs.map((item, index) => (
            <ZigZagItem
              key={index}
              {...item}
              isOdd={index % 2 === 0}
              isLast={index === paragraphs.length - 1}
            />
          ))
        : null}
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 100%;
  padding: 0 2rem;
`
