import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/FlexBox'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  image?: ImageProps
  isOdd?: boolean
  isLast?: boolean
  title?: string
  text?: string
}

export const ZigZagItem = memo(function ZigZagItem({
  image,
  isOdd,
  isLast,
  title,
  text,
}: Props) {
  const classes = `${image ? '' : 'align-center'}${isLast ? ' is-last' : ''}${
    isOdd ? ' is-odd' : ''
  }`

  return (
    <Container dial={5} row className={classes} tag="section">
      {image ? (
        <ImageWrap className="zig-zag-image-wrap">
          <LazyLoadComponent>
            <Image {...image} />
          </LazyLoadComponent>
        </ImageWrap>
      ) : null}
      <TextWrap>
        {title ? <Title>{title}</Title> : null}
        {text ? <Text dangerouslySetInnerHTML={{ __html: text }} /> : null}
      </TextWrap>
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 6rem 0;
  position: relative;
  &.align-center {
    text-align: center;
  }
  &.is-odd {
    .zig-zag-image-wrap {
      order: 2;
      img {
        margin-right: 0;
        margin-left: 4rem;
      }
    }
  }
  &.is-last {
    &:after {
      display: none;
    }
  }
  &:after {
    content: '';
    width: 60vw;
    height: 50px;
    position: absolute;
    bottom: -50px;
    left: 50%;
    background: -webkit-radial-gradient(
      50% 0%,
      70% 20%,
      rgba(200, 200, 200, 1) 0%,
      rgba(255, 0, 0, 0) 70%
    );
    transform: translateX(-50%);
  }

  @media (max-width: 767px) {
    display: block;
    text-align: center;
  }
`

const ImageWrap = styled.div`
  .lazy-load-image-background {
    width: auto !important;
    height: auto !important;
    position: relative;
    top: auto;
    left: auto;
  }

  img {
    width: auto;
    height: auto;
    max-width: 50rem;
    max-height: 31.25rem;
    margin-right: 4rem;

    @media (max-width: 767px) {
      width: auto;
      max-height: 18.75rem;
      margin: 0 auto 4rem auto;
    }
  }
`

const TextWrap = styled.div`
  width: 40%;

  @media (max-width: 767px) {
    width: 100%;
  }
`

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2rem;
  margin-bottom: 2rem;
`

const Text = styled.div`
  line-height: 1.5rem;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
`
